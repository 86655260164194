import BBCodeParser from 'js-bbcode-parser/src/index';

const bbCodeParser = new BBCodeParser({
  '\\[br\\]': '<br>',

  '\\[b\\](.+?)\\[/b\\]': '<strong>$1</strong>',
  '\\[i\\](.+?)\\[/i\\]': '<em>$1</em>',
  '\\[u\\](.+?)\\[/u\\]': '<u>$1</u>',

  '\\[h1\\](.+?)\\[/h1\\]': '<h1>$1</h1>',
  '\\[h2\\](.+?)\\[/h2\\]': '<h2>$1</h2>',
  '\\[h3\\](.+?)\\[/h3\\]': '<h3>$1</h3>',
  '\\[h4\\](.+?)\\[/h4\\]': '<h4>$1</h4>',
  '\\[h5\\](.+?)\\[/h5\\]': '<h5>$1</h5>',
  '\\[h6\\](.+?)\\[/h6\\]': '<h6>$1</h6>',

  '\\[p\\](.+?)\\[/p\\]': '<p>$1</p>',

  '\\[color=(.+?)\\](.+?)\\[/color\\]': '<span style="color:$1">$2</span>',
  '\\[size=([0-9]+)\\](.+?)\\[/size\\]': '<span style="font-size:$1px">$2</span>',

  '\\[img\\](.+?)\\[/img\\]': '<img src="$1">',
  '\\[img=(.+?)\\]': '<img src="$1">',

  '\\[email\\](.+?)\\[/email\\]': '<a href="mailto:$1">$1</a>',
  '\\[email=(.+?)\\](.+?)\\[/email\\]': '<a href="mailto:$1">$2</a>',

  '\\[url\\](.+?)\\[/url\\]': '<a href="$1">$1</a>',
  '\\[url=(.+?)\\|onclick\\](.+?)\\[/url\\]': '<a onclick="$1">$2</a>',
  '\\[url=(.+?)\\starget=(.+?)\\](.+?)\\[/url\\]': '<a href="$1" target="$2">$3</a>',
  '\\[url=(.+?)\\](.+?)\\[/url\\]': '<a href="$1">$2</a>',

  '\\[a=(.+?)\\](.+?)\\[/a\\]': '<a href="$1" name="$1">$2</a>',

  '\\[list\\](.+?)\\[/list\\]': '<ul>$1</ul>',
  '\\[list-ol\\](.+?)\\[/list-ol\\]': '<ol>$1</ol>',
  '\\[\\*\\](.+?)\\[/\\*\\]': '<li>$1</li>',
});

export function convertedHtmlToBBCode(html) {
  let convertedHtml = html;
  // br
  convertedHtml = convertedHtml.replace(/<br(.*?)>/gi, '\n');
  // b
  convertedHtml = convertedHtml.replace(/<strong>/gi, '[b]');
  convertedHtml = convertedHtml.replace(/<\/strong>/gi, '[/b]');
  convertedHtml = convertedHtml.replace(/<b>/gi, '[b]');
  convertedHtml = convertedHtml.replace(/<\/b>/gi, '[/b]');
  // i
  convertedHtml = convertedHtml.replace(/<em>/gi, '[i]');
  convertedHtml = convertedHtml.replace(/<\/em>/gi, '[/i]');
  convertedHtml = convertedHtml.replace(/<i>/gi, '[i]');
  convertedHtml = convertedHtml.replace(/<\/i>/gi, '[/i]');
  convertedHtml = convertedHtml.replace(/<cite>/gi, '[i]');
  convertedHtml = convertedHtml.replace(/<\/cite>/gi, '[/i]');
  // u
  convertedHtml = convertedHtml.replace(/<u>/gi, '[u]');
  convertedHtml = convertedHtml.replace(/<\/u>/gi, '[/u]');
  // h
  convertedHtml = convertedHtml.replace(/<h([1-7]).*?>(.*?)<\/h[1-7]>/, '\n[h$1]$2[/h$1]\n');
  // p
  convertedHtml = convertedHtml.replace(/<p.*?>/gi, '[p]');
  convertedHtml = convertedHtml.replace(/<\/p>/gi, '[/p]');
  // color
  convertedHtml = convertedHtml.replace(/<font color="(.*?)">(.*?)<\/font>/gmi, '[color=$1]$2[/color]');
  convertedHtml = convertedHtml.replace(/<font color=(.*?)>(.*?)<\/font>/gmi, '[color=$1]$2[/color]');
  convertedHtml = convertedHtml.replace(/<span style="color:(.*?)">(.*?)<\/span>/gmi, '[color=$1]$2[/color]');
  // link
  convertedHtml = convertedHtml.replace(/<a(.*?)href="(.*?)"(.*?)>(.*?)<\/a>/gi, '[url=$2]$4[/url]');
  // images
  convertedHtml = convertedHtml.replace(/<img(.*?)src="(.*?)"(.*?)>/gi, '[img]$2[/img]');
  // list
  convertedHtml = convertedHtml.replace(/<li(.*?)>(.*?)<\/li>/gi, '[*]$2[/*]');
  convertedHtml = convertedHtml.replace(/<ul(.*?)>/gi, '[list]');
  convertedHtml = convertedHtml.replace(/<\/ul>/gi, '[/list]');
  convertedHtml = convertedHtml.replace(/<ol(.*?)>/gi, '[list-ol]');
  convertedHtml = convertedHtml.replace(/<\/ol>/gi, '[/list-ol]');

  // clear
  convertedHtml = convertedHtml.replace(/<link(.*?)>/gi, '');
  convertedHtml = convertedHtml.replace(/<div>/gi, '\n');
  convertedHtml = convertedHtml.replace(/<\/div>/gi, '\n');
  convertedHtml = convertedHtml.replace(/<td(.*?)>/gi, ' ');
  convertedHtml = convertedHtml.replace(/<tr(.*?)>/gi, '\n');
  convertedHtml = convertedHtml.replace(/<head>(.*?)<\/head>/gmi, '');
  convertedHtml = convertedHtml.replace(/<object>(.*?)<\/object>/gmi, '');
  convertedHtml = convertedHtml.replace(/<script(.*?)>(.*?)<\/script>/gmi, '');
  convertedHtml = convertedHtml.replace(/<style(.*?)>(.*?)<\/style>/gmi, '');
  convertedHtml = convertedHtml.replace(/<title>(.*?)<\/title>/gmi, '');
  convertedHtml = convertedHtml.replace(/<!--(.*?)-->/gmi, '\n');
  convertedHtml = convertedHtml.replace(/\/\//gi, '/');
  convertedHtml = convertedHtml.replace(/<(?:[^>'"]*|(['"]).*?\1)*>/gmi, '');
  convertedHtml = convertedHtml.replace(/\r\r/gi, '');
  convertedHtml = convertedHtml.replace(/(\S)\n/gi, '$1 ');

  return convertedHtml;
}

export function parseBBCode(text) {
  return bbCodeParser
    .parse(text)
    .replace(/<a\s/g, '<a data-href-type="electron" ');
}
