<template>
  <div class="p-m-4">
    <Card class="p-m-4">
      <template #title>
        Russian rules:
      </template>
      <template #content>
        <EditorText v-model="rules.ru"/>
        <Button
          @click.prevent="showResultRu=!showResultRu"
          label="Result"
          class="p-mt-2"
        />
        <div v-show="showResultRu">
          <div v-html="rulesRuBBCode"/>
        </div>
      </template>
    </Card>

    <Card class="p-m-4">
      <template #title>
        English rules:
      </template>
      <template #content>
        <EditorText v-model="rules.en"/>
        <Button
          @click.prevent="showResultEn=!showResultEn"
          label="Result"
          class="p-mt-2"
        />
        <div v-show="showResultEn">
          <div v-html="rulesEnBBCode"/>
        </div>
      </template>
    </Card>

    <div>
      <div>Version of rules:</div>
      <InputText
        v-model.number="rules.version"
        type="number"
      />
      <small>Please, only up version</small>
    </div>
    <Button
      :disabled="!isAllowPermissions"
      @click.prevent="onSubmit"
      label="Update"
    />
    <!-- todo reset -->
  </div>
</template>

<script>
import EditorText from '@/components/EditorText.vue';
import {
  convertedHtmlToBBCode,
  parseBBCode,
} from '@/utils/editor';
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  name: 'RulesEdit',
  components: {
    EditorText,
    Card,
    InputText,
    Button,
  },
  data() {
    return {
      rules: {
        ru: parseBBCode(this.currentRules.ru),
        en: parseBBCode(this.currentRules.en),
        version: this.currentRules.version,
      },
      showResultRu: false,
      showResultEn: false,
    };
  },
  props: {
    currentRules: {
      type: Object,
    },
    isAllowPermissions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    convertedHtmlToBBCodeRu() {
      return convertedHtmlToBBCode(this.rules.ru);
    },
    convertedHtmlToBBCodeEn() {
      return convertedHtmlToBBCode(this.rules.en);
    },
    rulesRuBBCode() {
      return parseBBCode(this.convertedHtmlToBBCodeRu);
    },
    rulesEnBBCode() {
      return parseBBCode(this.convertedHtmlToBBCodeEn);
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', {
        ru: this.convertedHtmlToBBCodeRu,
        en: this.convertedHtmlToBBCodeEn,
        version: this.rules.version,
      });
    },
  },
};
</script>
