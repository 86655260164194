<template>
  <div class="users">
    <h4>
      News
      <b-button variant="outline-primary" size="sm" @click="getNews">Get rules</b-button>
    </h4>
    <loading v-if="isLoading"/>
    <div
      v-else
      @submit="onSubmit"
    >
      {{ list }}
    </div>
  </div>
</template>

<script>
import sha from 'js-sha512';
import { mapActions, mapGetters, mapState } from 'vuex';
import Loading from '@/components/Loading.vue';

const { sha512 } = sha;
export default {
  name: 'News',
  components: {
    Loading,
  },
  created() {
    this.getNews();
  },
  computed: {
    key() {
      return sha512(JSON.stringify(this.currentRules));
    },
    ...mapState({
      list: (state) => state.news.list,
      isLoading: (state) => state.rules.loading,
    }),
    ...mapGetters(['isAllowNews']),
  },
  methods: {
    onSubmit(rules) {
      if (rules.version < this.currentRules.version
        && !window.confirm('Do you want downgrade version?')) {
        return;
      }
      if (!rules.en || !rules.ru) {
        alert('Empty rules');
      }
      this.setRules({ rules })
        .then((isSuccess) => {
          if (isSuccess) {
            this.getNews();
          } else {
            alert('Error. Check console');
          }
        });
    },
    ...mapActions({
      getNews: 'news/getNews',
    }),
  },
};
</script>

<style scoped>

</style>
