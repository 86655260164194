<template>
  <div>
    <Editor
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #toolbar>
        <select class="ql-header" defaultValue="0">
          <option value="1">H1</option>
          <option value="2">H2</option>
          <option value="3">H3</option>
          <option value="4">H4</option>
          <option value="5">H5</option>
          <option value="6">H6</option>
          <option value="0">Normal</option>
        </select>
        <span class="ql-formats">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered" type="button"></button>
          <button class="ql-list" value="bullet" type="button"></button>
          <select class="ql-align">
            <option defaultValue></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-link" type="button"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-clean" type="button"></button>
        </span>
      </template>
    </Editor>
  </div>
</template>

<script>
import Editor from 'primevue/editor';

export default {
  name: 'EditorText',
  components: {
    Editor,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
};
</script>
