<template>
  <div>
    <h4>
      Rules for user
    </h4>
    <div>
      <Button
        @click="getRules">
        Get rules
      </Button>
    </div>
    <Loading v-if="isLoading"/>
    <rules-edit
      v-else
      :key="key"
      :is-allow-permissions="isAllowRules"
      :current-rules="currentRules"
      @submit="onSubmit"/>
  </div>
</template>

<script>
import sha from 'js-sha512';
import { mapActions, mapGetters, mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import RulesEdit from '@/components/RulesEdit.vue';
import Button from 'primevue/button';

const { sha512 } = sha;
export default {
  name: 'Rules',
  components: {
    Loading,
    RulesEdit,
    Button,
  },
  created() {
    this.getRules();
  },
  computed: {
    key() {
      return sha512(JSON.stringify(this.currentRules));
    },
    ...mapState({
      currentRules: (state) => state.rules.current,
      isLoading: (state) => state.rules.loading,
    }),
    ...mapGetters(['isAllowRules']),
  },
  methods: {
    onSubmit(rules) {
      if (rules.version < this.currentRules.version
        && !window.confirm('Do you want downgrade version?')) {
        return;
      }
      if (!rules.en || !rules.ru) {
        alert('Empty rules');
      }
      this.setRules({ rules })
        .then((isSuccess) => {
          if (isSuccess) {
            this.getRules();
          } else {
            alert('Error. Check console');
          }
        });
    },
    ...mapActions({
      getRules: 'rules/getRules',
      setRules: 'rules/setRules',
    }),
  },
};
</script>
